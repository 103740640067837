import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import Layout from "../components/layout"
import Seo from "../components/seo"

const HotelPage = () => (
  <Layout>
    <Seo title="CSICon Hotel Information" />
    <div class="row page-row">
      <div class="col-12 col-lg-8">
        <div class="page-header resouce">
          <hr />
          <h1>
            <strong>Hotel Information</strong>
          </h1>
        </div>

        <div class="entry-content conference-max mt-3">
          <StaticImage
            src="../images/flamingopool.jpeg"
            alt="Flamingo Hotel Las Vegas"
            width="1024"
            height="680"
            className="aligncenter size-full wp-image-587"
            loading="lazy"
          />

          <p>
            CSICon 2022 takes place in the conference area of the Flamingo Hotel
            and Casino.
          </p>
          <p>
            If you haven’t booked your room yet for CSICon, we have oversold our
            room block at the Flamingo, and we cannot get any more there. The
            Flamingo is completely sold out. We have worked to secure some
            additional rooms at the Tuscany.
          </p>
          <p>
            We have worked to secure some additional rooms at Tuscany. At the
            Tuscany you can book rooms for October 19 -23 at the rate of
            $269.00, with no resort fee. The Tuscany is a two-block walk from
            the Flamingo. Please use the link to book your room at the Tuscany.
          </p>
          <p>Please use the link to book your room at the Tuscany</p>
          <p>
            <a href="https://res.windsurfercrs.com/ibe/details.aspx?propertyid=16539&nights=1&checkin=10/19/2022&group=1022CENTER&lang=en-us">
              Tuscany Room Block
            </a>
          </p>
          <p>
            The hotel does not have a shuttle from the airport. Taxis are
            available 24 hours a day, and, there are several difference shuttle
            companies to choose from:
          </p>
          <p></p>
          <p>
            <a href="https://www.vegas4locals.com/resources/las-vegas-airport-shuttles/">
              https://www.vegas4locals.com/resources/las-vegas-airport-shuttles/
            </a>
          </p>
          <p></p>
          <p>I have taken this multiple occasions.</p>
          <p>
            Supershuttle – 1 800 258 3826{" "}
            <a href="https://www.supershuttle.com/locations/lasvegas-las/">
              https://www.supershuttle.com/locations/lasvegas-las/
            </a>{" "}
            (advanced reservation)
          </p>
          <p></p>
          <p>
            I have also included a link to the Harry Reid (formerly McCarron)
            Airport Website that also has additional information regarding
            transportation to and from the airport.
          </p>
          <p></p>
          <p>
            <a href="https://www.harryreidairport.com/Transportation">
              https://www.harryreidairport.com/Transportation
            </a>
          </p>
          {/*} <table class="table">
            <tbody>
              <tr>
                <th>
                  Tuesday
                  <br />
                  10/15/2019
                </th>
                <th>
                  Wednesday
                  <br />
                  10/16/2019
                </th>
                <th>
                  Thursday
                  <br />
                  10/17/2019
                </th>
                <th>
                  Friday
                  <br />
                  10/18/2019
                </th>
                <th>
                  Saturday
                  <br />
                  10/19/2019
                </th>
                <th>
                  Sunday
                  <br />
                  10/20/2019
                </th>
                <th>
                  Monday
                  <br />
                  10/21/2019
                </th>
              </tr>
              <tr>
                <td>$89</td>
                <td>$89</td>
                <td>$89</td>
                <td>$129</td>
                <td>$129</td>
                <td>$89</td>
                <td>$89</td>
              </tr>
            </tbody>
          </table>*/}
          <p>
            <small>
              Optional workshops take place on Thursday. CSICon Opening
              Reception takes place Thursday evening. CSICon main program all
              day Friday and Saturday, and Sunday morning.
            </small>
          </p>
          {/*<p>
            <a
              href="https://book.passkey.com/go/SFCFI2"
              target="_blank"
              rel="noopener noreferrer"
            >
              <button class="btn green-button">Book Online</button>
            </a>
          </p>
          {/*<p>
            Or, make a reservation via phone by calling{" "}
            <a href="tel:888-373-9855">(888) 373-9855</a> (processing fees
            apply).
            <br />
            Tell them that you're in the <strong>CSICon</strong> group, or
            Center for Inquiry 2022, or you can use the group code, SFCFI2.
            </p>*/}
        </div>
      </div>
    </div>
  </Layout>
)

export default HotelPage
